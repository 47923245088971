import gql from 'graphql-tag';

export default {
  fetchPolicy: 'network-only',
  query: gql`
    query getSummaryV2(
      $labelId: UUID
      $releaseDateFrom: String
      $releaseDateTo: String
      $listensDateFrom: String
      $listensDateTo: String
    ) {
      ReleaseQuery {
        getSummaryV2(
          labelId: $labelId
          releaseDateFrom: $releaseDateFrom
          releaseDateTo: $releaseDateTo
          listensDateFrom: $listensDateFrom
          listensDateTo: $listensDateTo
        ) {
          labelId
          releasesCount
          tracksCount
          listens
        }
      }
    }
  `,
  error(error) {
    this.emitError(this.$t('entity.error.get'), error.message);
  },
};
