<template>
  <div class="aurora-statistic">
    <div class="aurora-statistic__title">
      <a-icon
        class="menu-toggle"
        type="menu"
        @click="toggleMenu"
      />
      <h1 class="ellipsis">{{ title }}</h1>
    </div>

    <div class="aurora-statistic__inputs">
      <div class="aurora-statistic__inputs-date">
        <p>{{ $t('aurora.dashboard.dateFrom') }}</p>
        <a-date-picker
          v-model="datePeriod[0]"
          class="datepicker"
          format="DD.MM.YYYY"
          locale="ru-RU"
          valueFormat="YYYY-MM-DD"
          :placeholder="$t('base.placeholder.selectDate')"
          size="small"
          :allowEmpty="true"
        />
      </div>
      <div class="aurora-statistic__inputs-date">
        <p>{{ $t('aurora.dashboard.dateTo') }}</p>
        <a-date-picker
          v-model="datePeriod[1]"
          class="datepicker"
          format="DD.MM.YYYY"
          locale="ru-RU"
          valueFormat="YYYY-MM-DD"
          :placeholder="$t('base.placeholder.selectDate')"
          size="small"
          :allowEmpty="true"
        />
      </div>
      <div class="aurora-statistic__inputs-label">
        <p>{{ $t('base.placeholder.enterField', { name: 'лейбл' }) }}</p>
        <a-select
          v-model="selectedLabel"
          class="label-select"
          :placeholder="$t('base.placeholder.enterField', { name: 'лейбл' })"
          size="small"
          dropdownClassName="input-enum__dropdown"
          allowClear
        >
          <a-select-option
            v-for="option of labelOptions"
            :key="option"
          >
            {{ option }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <a-spin :spinning="isLoading || loadingQueries > 0">
      <div class="spin-wrap">
        <div class="aurora-statistic__results">
          <div class="aurora-statistic__results-period">
            <h3 class="ellipsis">{{ $t('aurora.dashboard.forPeriod') }}</h3>
            <p>{{ $t('aurora.dashboard.releasesCount') }}{{ getSummary.releasesCount }}</p>
            <p>{{ $t('aurora.dashboard.tracksCount') }}{{ getSummary.tracksCount }}</p>
            <p>{{ $t('aurora.dashboard.lictensCountTotal') }}{{ getSummary.listens }}</p>
            <p>{{ $t('aurora.dashboard.qualitativeLictensCount') }}</p>
            <p>{{ $t('aurora.dashboard.activeLabelsCount') }}{{ getSummary.activeLabels }}</p>
          </div>
          <div class="aurora-statistic__results-total">
            <h3 class="ellipsis">{{ $t('aurora.dashboard.forEndOfPeriod') }}</h3>
            <p>
              {{ $t('aurora.dashboard.totalReleases') }}{{ releasesAndTracksCount?.totalReleases }}
            </p>
            <p>{{ $t('aurora.dashboard.totalTracks') }}{{ releasesAndTracksCount?.totalTracks }}</p>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<!-- eslint-disable indent -->
<!-- eslint-disable prettier-vue/prettier -->
<script>
import store from '@/store';
import { bus } from '@/helpers';
import GET_SUMMARY_V2 from '@/queries/auroraCustomQueries/getSummaryV2';

export default {
  name: 'AuroraStatistic',

  data() {
    return {
      isLoading: false,
      datePeriod: ['2020-01-07', '2024-02-03'],
      selectedLabel: null,
      loadingQueries: 0,
    };
  },

  apollo: {
    releasesAndTracksCount: {
      ...GET_SUMMARY_V2,
      loadingKey: 'loadingQueries',
      debounce: 250,
      variables() {
        return {
          ...(this.selectedLabel && { labelId: this.selectedLabel }),
          ...(this.datePeriod?.[1] && { releaseDateTo: this.datePeriod?.[1] }),
        };
      },
      update(data) {
        return data.ReleaseQuery.getSummaryV2.reduce(
          (acc, item) => {
            acc.totalReleases += item.releasesCount;
            acc.totalTracks += item.tracksCount;

            return acc;
          },
          { totalReleases: 0, totalTracks: 0 },
        );
      },
    },
    getSummary: {
      ...GET_SUMMARY_V2,
      loadingKey: 'loadingQueries',
      debounce: 250,
      variables() {
        return {
          ...(this.selectedLabel && { labelId: this.selectedLabel }),
          ...(this.datePeriod?.[0] && {
            releaseDateFrom: this.datePeriod?.[0],
            listensDateFrom: this.datePeriod?.[0],
          }),
          ...(this.datePeriod?.[1] && {
            releaseDateTo: this.datePeriod?.[1],
            listensDateTo: this.datePeriod?.[1],
          }),
        };
      },
      update(data) {
        return data?.ReleaseQuery?.getSummaryV2?.reduce(
          (acc, item) => {
            acc.listens += item.listens;
            acc.releasesCount += item.releasesCount;
            acc.tracksCount += item.tracksCount;
            if (item.tracksCount) {
              acc.activeLabels += 1;
            }
            return acc;
          },
          { listens: 0, releasesCount: 0, tracksCount: 0, activeLabels: 0 },
        );
      },
    },
  },

  computed: {
    fullscreenIcon() {
      return this.fullscreenModal ? 'fullscreen-exit' : 'fullscreen';
    },
    pageConfig() {
      return store.state.activeSidebarItem;
    },
    title() {
      return this.pageConfig?.title ?? '';
    },
    labelOptions() {
      return [];
    },
    isAdmin() {
      return false;
    },
  },

  methods: {
    toggleMenu() {
      bus.$emit('toggleMenu');
    },
  },
};
</script>

<style lang="scss">
.aurora-statistic {
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: visible;

  &__title {
    position: relative;
    flex-basis: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    height: 42px;

    h1 {
      display: inline-block;
      font-size: 28px;
      font-weight: 700;
      vertical-align: sub;
      margin-bottom: 0;
      margin-right: 30px;
    }

    .menu-toggle {
      width: 30px;
      margin-right: 15px;
    }
  }

  &__inputs {
    & > * {
      display: grid;
      grid-template-columns: 1fr;

      p {
        margin-bottom: 8px;
      }
      margin-bottom: 12px;
    }

    &-date {
      p {
        width: fit-content;
      }

      .datepicker {
        width: 100%;
        height: fit-content;
        .filter-timepicker {
          margin-bottom: 10px;
          display: block;
        }
      }
    }

    &-label {
      &__select {
        width: 100%;
      }

      &__dropdown.ant-select-dropdown {
        top: 32px !important;

        .ant-select-dropdown-menu {
          max-height: 5 * 32px;
        }
      }
    }
  }
}

@media (min-width: $desktopBreakpoint) {
  .aurora-statistic {
    @include scrollbars();
    height: 100%;
    overflow-y: scroll;

    &.noscroll {
      overflow-y: hidden;
    }

    &__inputs {
      & > * {
        grid-template-columns: 1fr 1fr;
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .menu--vertical .aurora-statistic {
    padding-left: 40px;
    padding-right: 26px;

    &.noscroll {
      padding-right: 34px;
    }
  }

  .menu--horizontal .aurora-statistic {
    padding-left: 24px;
    padding-right: 24px;

    &.noscroll {
      padding-right: 32px;
    }
  }
}
</style>
